.button {
  border-radius: 8px;
  color: white;
  background: var(--orange-gradient);
  padding: min(3vw, 14px) min(7vw, 40px);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: all 200ms ease;
  position: relative;
  font-size: 16px;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content.left {
  transform: translateX(-10px);
}

.content.right {
  transform: translateX(10px);
  flex-direction: row-reverse;
}

.content.loading {
  transform: translateX(0px);
}

.primary {
  background: var(--orange-gradient);
}

.secondary {
  background: var(--secondary-900);
  color: white;
}

.laptop-secondary:hover {
  background: var(--secondary-700);
}

.primary-transparent {
  background: white;
  color: var(--orange);
}

.secondary-transparent {
  background: white;
  color: var(--secondary-900);
}

.primary-outline {
  background: white;
  color: var(--orange);
  border: 1px solid var(--orange);
}

.laptop-primary-outline:hover {
  background: var(--orange);
  color: white;
}

.secondary-outline {
  background: white;
  color: var(--secondary-900);
  border: 1px solid var(--secondary-900);
}

.laptop-secondary-outline:hover {
  background: var(--secondary-900);
  color: white;
}

.danger {
  background: var(--error);
  color: white;
}

.laptop-danger:hover {
  background: var(--error-light);
  color: var(--error-dark);
}

.button h6 {
  font-weight: 600;
  font-size: 16px !important;
}

.fw {
  width: 100%;
}

.icon {
  margin: 0 10px;
}

.disabled {
  background: var(--disabled-background) !important;
  color: var(--disabled-text) !important;
  cursor: auto !important;
}
