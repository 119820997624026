/* Plus Jakarta Sans - Extra Light */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Plus Jakarta Sans - Extra Light Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* Plus Jakarta Sans - Light */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Plus Jakarta Sans - Light Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Plus Jakarta Sans - Regular */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Plus Jakarta Sans - Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Plus Jakarta Sans - Medium */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Plus Jakarta Sans - Medium Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Plus Jakarta Sans - Semi Bold */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Plus Jakarta Sans - Semi Bold Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Plus Jakarta Sans - Bold */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Plus Jakarta Sans - Bold Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Plus Jakarta Sans - Extra Bold */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Plus Jakarta Sans - Extra Bold Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
