.wrapper {
  width: 100%;
  position: relative;
  padding: 0 3px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 7px;
  padding: 7px 16px;
  background: var(--input-background);
  border: 0.5px solid var(--secondary-400, #94a3b9);
  margin: 5px 0px;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.container:focus-within {
  outline: 1px solid var(--secondary-400, #94a3b9);
  box-sizing: border-box;
}

.disabled {
  color: rgb(170, 170, 170) !important;
  background: var(--disabled-background) !important;
}

/* Header */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.label {
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
  font-size: 14px;
}

.label span {
  color: var(--orange);
}

.link {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-900);
}

/* Error */

.error-container {
  background: var(--error-light);
  border-radius: 5px;
  padding: 5px 10px;
}

.message {
  font-weight: 500;
  color: var(--error-dark);
  font-size: 14px;
}
