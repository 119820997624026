.container {
  flex: 1;
  background: white;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}
