.map-view-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.search-this {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 25px;
  text-align: center;
  background: white;
  box-shadow: var(--box-shadow);
  z-index: 10;
  cursor: pointer;
}

.search-this p {
  font-size: 14px;
  font-weight: 500;
}
