.container {
  margin: 10px 0;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  background: white;
  padding: 20px;
  position: relative;
  width: 100%;
}

.chevron {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 16px;
}

.status {
  font-size: 14px;
  margin-bottom: 10px;
}

.status.Active {
  color: var(--success);
}

.status.Processing {
  color: var(--orange);
}

.title {
  margin: 15px 0;
}

.completed-shifts {
    font-weight: 600;
    margin: 10px 0;
}