.container {
  margin: 20px 0;
  box-shadow: var(--box-shadow);
  padding: 20px;
  border-radius: 15px;
  position: relative;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.name {
  font-size: 14px;
}

.value {
  font-size: 12px;
  font-weight: 600;
}

.divider {
  border-bottom: 1px dashed var(--secondary-300);
  background: var(--secondary-300);
  flex: 1;
  margin: 0 10px;
}

.title {
  margin: 0 10px 20px 10px;
  font-size: 14px;
  font-weight: 600;
}

.icon {
  box-shadow: var(--box-shadow);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
}
