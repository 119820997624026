.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: all 200ms ease;
  opacity: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
  pointer-events: all;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
}

.content h1 {
  white-space: nowrap;
}

.content svg {
  font-size: 30px;
  margin-left: 40px;
}

.blob {
  position: absolute;
  z-index: 1;
}

.blob-1 {
  top: 0%;
  left: -40%;
  width: 80vw;
}

.blob-2 {
  top: -50%;
  right: -20%;
  width: 50vw;
}
.blob-3 {
  bottom: -20%;
  right: -30%;
  width: 110vw;
}
