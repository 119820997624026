.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex: 1;
}
.no-conversations {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: fs;
  flex-direction: column;
  height: 100%;
}

.no-conversations p {
  margin: 20px 0 40px 0;
  font-weight: 500;
}

.no-conversations button {
  width: 80%;
}

.no-convo-image-xs {
  width: 70%;
}

.all {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.title {
  padding: 20px;
}

.messages {
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.loading-message {
  margin-top: 20px;
}

.message-loader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  padding: 0 20px;
}

.skeleton-lines {
  margin-left: 20px;
}

.loading-pin-skel {
  margin-right: 15px;
}
