.container {
  position: relative;
  height: calc(100vh - 80px);
  overflow: auto;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}

/* Background */

.background {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.background-drop {
  height: 30vh;
  background: var(--primary-800);
  position: relative;
}

.blob {
  position: absolute;
  z-index: 1;
}

.blob-1 {
  top: -0;
  right: -20%;
  width: 40vw;
}

.blob-3 {
  top: -5%;
  left: -20%;
  width: 80vw;
}

/* Progress Card */
.progress-container {
  margin: 20px;
  padding-top: 90px;
}

.progress-card {
  background: white;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  position: relative;
  padding-top: 20px;
}

.nav-links {
  margin-top: 15px;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  border-top: 1px solid var(--border);
}

.nav-icon {
  margin: 0 15px;
}

.nav-chevron {
  font-size: 14px;
  margin-left: auto;
}

.nav-disabled {
  background: var(--greyscale-50);
  color: var(--secondary-600) !important;
}

/* Progress Spinner */

.progress-spinner-container {
  position: relative;
}

.progress-spinner-initial {
  background: var(--secondary-900);
  color: white;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -65%);
  overflow: hidden;
  border: 3px solid var(--secondary-900);
}

.progress-spinner-initial img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progress-img-skeleton {
  transform: translateY(-3px);
}

.progress-spinner-add {
  position: absolute;
  bottom: -0px;
  right: -10px;
  background: var(--secondary-400);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  box-shadow: var(--box-shadow);
  color: var(--secondary-900);
  font-size: 18px;
}

.progress-details {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 60px;
}

.progress-details h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.progress-details p {
  line-height: 1.5;
  font-weight: 100;
}

/* Profress Bio */
.bio-container {
  padding: 20px;
}

.bio-title {
  margin: 0px 0 20px 0;
}

.bio-text-area {
  height: 50vh;
}

.save-bio-btn {
  width: 100%;
}

.bio-text-area textarea {
  line-height: 1.8 !important;
  height: 100%;
}

/* Profile Stats */
.stats-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: center;
  margin: 20px 0;
}

.progress-stat {
  width: 30%;
}

.p-stat-text {
  font-size: 12px;
  color: var(--secondary-400);
  margin-top: 10px;
}

.p-stat-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-stat-value span {
  font-size: 12px;
  margin-left: 5px;
}

/* Progress Card Actions */
.progress-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Cert Tabs */
.cert-tabs-container {
  margin: 20px 0;
  width: 100%;
  padding: 10px 5px;
  background: white;
  box-shadow: var(--box-shadow);
}

.cert-tabs {
  border-bottom: 3px solid var(--border);
}

.cert-tabs-content {
  padding: 0 0px 10px 0px;
}

.certs-add-cert {
  padding: 0 20px;
}

.certs-add-cert-tbn {
  width: 100%;
  margin-top: 20px;
}

/* Finsih Profile Setup */

.finish-container {
  margin: 10px 0;
}

.finish-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 30px;
}

.finish-heading span {
  background: var(--error-500);
  color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.finish-heading h4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finish-content {
  display: flex;
  width: auto;
  overflow-x: auto; /* Enable horizontal scrolling */
  flex-wrap: nowrap;
  padding: 20px 0;
}

.finish-card {
  min-width: 150px;
  position: relative;
  height: 150px;
  background: var(--secondary-100);
  border-radius: 15px;
  margin: 0 5px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.finish-card p {
  text-align: center;
  margin-top: 20px;
}

.finish-card-icon {
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-900);
  color: white;
  border-radius: 50%;
}

.finish-card:first-child {
  margin-left: 20px;
}

.finish-card:last-child {
  margin-right: 40px;
}

.high-priority {
  background: var(--error-50);
  color: var(--error-500);
}

.high-priority .finish-card-icon {
  background: var(--error-500);
}

.finish-notice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px 20px 20px;
}

.finish-notice p {
  margin-left: 20px;
}

.finish-notice span {
  height: 25px;
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-700);
  color: white;
  border-radius: 50%;
}

/* Profile Navigation */
.profile-nav-container {
  margin-bottom: 20px;
  padding: 20px;
}

.profile-nav-container h4 {
  margin-bottom: 20px;
}

.profile-nav-card-bottom {
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin: 10px 0;
}

.profile-nav-bottom-details {
  display: flex;
  align-items: center;
}

.profile-nav-bottom-details p {
  margin-left: 20px;
}

.logout-btn {
  margin-top: 20px;
  width: 100%;
}

.profile-nav-card-badge {
  background: var(--error);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 15px;
}
