.button {
  border-radius: 8px;
  color: white;
  background: var(--orange-gradient);
  padding: max(4vw, 16px) max(5vw, 18px);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1rem;
  transition: all 200ms ease;
  position: relative;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content.left {
  transform: translateX(10px);
}

.content.right {
  transform: translateX(-10px);
}

.primary {
  background: var(--orange-gradient);
}

.secondary {
  background: var(--secondary-1000);
}

.button:disabled {
  background: var(--disabled-background) !important;
}

.button p {
  font-weight: 600;
}

.loading {
  color: white;
  background-color: var(--secondary-300);
}

.icon {
  margin: 0 10px;
}
