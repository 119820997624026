.map-container {
  width: 100%;
  height: 100%;
}

.marker-icon {
  background: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(145, 145, 145, 0.1);
  border-radius: 15px;
}

.marker-icon img {
  width: 20px;
  margin-right: 10px;
}

.marker-icon p {
  font-size: 14px;
  font-weight: 600;
}
