.container {
  position: fixed;
  width: 100%;
  height: calc(100vh - 8vh);
  background: white;
  z-index: 999;
  transition: all 300ms ease;
  border-radius: 15px 15px 0 0;
}

.bottom {
  top: 100vh;
  left: 0;
}

.top {
  top: -92vh;
  left: 0;
}

.left {
  top: 8vh;
  left: -100vw;
}

.right {
  top: 8vh;
  right: -100vw;
}

.visible {
  top: 8vh;
  left: 0;
  right: 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.close-icon {
  font-size: 20px;
  position: absolute;
  top: 25px;
  right: 20px;
}
