.container {
  height: calc(100vh - 90px);
  overflow: scroll;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  padding: 60px 20px 20px 20px;
}

.content {
  background: var(--secondary-200);
  position: relative;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.illustration {
  margin: 0 auto;
  width: 80%;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 18px;
}

.content-text h3 {
  margin-bottom: 10px;
}

.content-text p {
  font-size: 14px;
}

.strong-text {
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0;
  line-height: 1.8;
}

.strong-text span {
  color: var(--orange);
}

.shifts-links {
  margin-top: 20px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
}

.link p {
  font-weight: 600;
}
