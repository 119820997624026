.container {
  position: absolute;
  bottom: -100%;
  right: 0;
  width: 100vw;
  height: 50vh;
  background: white;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  z-index: 101;
  padding: 15px;
  transition: all 300ms ease;
}

.open {
    bottom: 0;
}

.heading {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border);
}

.title {
  padding: 15px 0;
}

.title p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.slide {
  padding: 0 20px 40px 20px;
}
