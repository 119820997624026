.container {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: all 250ms ease-in-out;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.heading {
  box-shadow: var(--box-shadow);
  position: fixed;
  top: 0;
  left: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  transition: all 250ms ease-in-out;
  z-index: 101;
}

.visible {
  left: 0;
}

.heading-chevron {
  position: absolute;
  top: 25px;
  left: 20px;
}
.heading-options {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.heading-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 10px;
  overflow: hidden;
  margin-left: 60px;
}

.heading-title {
  font-weight: 700;
  font-size: 16px;
}

.messages-container {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 85px);
  background: var(--secondary-100);
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  min-height: calc(100vh - 100px);
}

.message-bar {
  padding: 0 20px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messages-input {
  width: 100%;
  transform: translateY(8px);
}

.send-button {
  margin-left: 10px;
}
