.container {
  width: 100%;
}

.searchbox-container {
  padding: 10px 15px;
  width: 100%;
}

.searchbox {
  box-shadow: 0px 4px 15px 1px rgba(173, 173, 173, 0.15);
  background: var(--primary-50);
  border: 1px solid var(--orange);
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.search-icon svg {
  color: var(--orange);
  font-size: 23px;
}

.filterbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 50px;
  width: 50px;
  margin: 15px;
  border: 1px solid var(--orange);
  background: white;
}

.filterbox svg {
  width: 20px;
  height: 20px;
}

.icon {
  padding: 10px;
}

.filter-header {
  font-weight: 700;
  font-size: 14px;
}

.filter-addnotes {
  color: var(--secondary-600);
  margin-top: 0px;
}

.filter-addnotes svg {
  margin-left: 10px;
}
