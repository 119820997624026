.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}



.content {
  flex: 1;
  overflow: scroll;
  width: 100%;
  padding: 0 10px;
}
