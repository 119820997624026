.link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
}

.link-badge {
  background: var(--error);
  color: white;
  border-radius: 25px;
  font-size: 10px;
  padding: 3px 8px;
  margin-left: auto;
  margin-right: 15px;
}

.form-container {
  padding: 20px;
}

.save-btn {
  margin-top: 20px;
  width: 100%;
}

.checkbox-container {
  margin: 30px 0;
}

.toggle-container {
  margin: 20px 0;
}
