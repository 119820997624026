.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.tile-controller {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.label {
  font-weight: 500;
  margin-bottom: 15px;
  white-space: nowrap;
  font-size: 14px;
}

.label span {
  color: var(--orange);
  margin-left: 3px;
}

/* Error */

.error-container {
  background: var(--error-light);
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 20px;
}

.message {
  font-weight: 500;
  color: var(--error-dark);
  font-size: 14px;
}

@media only screen and (min-width: 1024px) {
  .calendar {
    font-size: 14px !important;
  }
}
