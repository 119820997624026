.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.flex-container > div {
  padding-right: 15px;
}

.flex-container > div:last-child {
  padding-right: 0;
}

@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .flex-container div {
    padding-right: 0;
    padding-bottom: 15px;
  }

  .flex-container div:last-child {
    padding-bottom: 0;
  }
}
