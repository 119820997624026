.container {
  height: 100vh;
  width: 100vw;
  background: white;
  position: relative;
  padding: 0 1px;
}

.content-container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.back-arrow {
  position: fixed;
  top: 15vw;
  left: 5vw;
}

.heading {
  margin: 10px 0;
}

.heading span {
  color: var(--orange);
}

.subtext {
  margin: 5px 0;
}

.content {
  flex: 1;
  margin: 10px 0;
  width: 100%;
}

.button-container {
  width: 100%;
}

.button {
  width: 100%;
}

.nav-text {
  margin: 20px 0;
  text-align: center;
}

.nav-text span {
  color: var(--orange);
  font-weight: 500;
}

.login-forgot-password {
  font-weight: 500;
}

.login-forgot-password span {
  color: var(--orange);
}

/* Reset */
