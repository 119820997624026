.MuiChip-root {
  background-color: #fdece3; /* Light background */
  border: none; /* No border */
  font-size: 0.875rem; /* Adjust font size as needed */
  font-weight: bold; /* Bold font */
  color: var(--organge); /* Text color */
  border-radius: 4px !important; /* Rounded corners */
}

.MuiChip-deleteIcon {
  color: var(--orange); /* Same color as the text for the delete icon */
  margin-left: 2px; /* Adjust spacing between text and icon */
}

.css-1i6sjat-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  color: inherit !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  /* border-radius: 10px !important; */
  border-width: 1px !important;
  border-color: var(--border) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--border) !important;
  border-width: 1px !important;
  border-radius: 10px !important;
}

.MuiSlider-track {
  border: none !important;
}

.MuiAutocomplete-input {
  color: black;
  padding: 0 !important;
}

.MuiInputBase-root {
  font-family: "Plus Jakarta Sans" !important;
  background: var(--input-background) !important;
  border-radius: 7px !important;
  margin: 0px 0;
}

.MuiInputBase-input::placeholder {
  color: var(--secondary-400) !important;
  font-size: 16px !important;
  font-family: "Plus Jakarta Sans" !important;
  opacity: 0.9;
}

.MuiInputBase-input {
  padding: 0 !important;
}

.MuiInputBase-root fieldset {
  display: none;
}

.MuiInputBase-input::placeholder {
  color: var(--secondary-300, #cdd9e3) !important;
  font-size: 16px;
  display: none;
}

/* Table */
.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-cell {
  outline: none !important;
  overflow: visible !important;
}

.MuiDataGrid-row {
  outline: none !important;
  overflow: visible !important;
}
