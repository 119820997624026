.container {
  flex: 1;
  background: var(--secondary-50);
  position: relative;
  overflow: hidden;
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skeleton-wrapper {
  height: calc(90vh - 260px);
  width: 85vw;
  background: rgb(250, 250, 250);
  position: absolute;
  box-shadow: 0px 4px 15px 1px rgba(173, 173, 173, 0.2);
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
}

.skeleton-image {
  border-radius: 15px;
  margin-bottom: 20px;
}

.skeleton-text {
  margin: 10px 0;
}

.stack-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 10vh;
}

.info-icon {
  position: absolute;
  right: 10px;
  bottom: 20px;
  font-size: 16px;
  color: var(--secondary-800);
  background: white;
  height: 30px;
  width: 30px;
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.shift-container {
  height: calc(90vh - 280px);
  width: 85vw;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-shadow: 0px 4px 15px 1px rgba(173, 173, 173, 0.2);
  border-radius: 15px;
  overflow: hidden;
}

.shift {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shift-swiper-container {
  height: 50%;
  border-radius: 15px;
  position: relative;
}

.shift-swiper-container .swiper-slide {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.shift-swiper-container .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.business-logo {
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  position: absolute;
  bottom: -15%;
  left: 5%;
  box-shadow: 0px 4px 15px 1px rgba(173, 173, 173, 0.2);
  overflow: hidden;
}

.information-icon {
  height: 30px;
  width: 30px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  color: var(--orange);
}

.hourly-rate {
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 0 5px 5px 0;
  background: var(--orange);
  color: white;
  padding: 5px 20px;
}

.hourly-rate p {
  font-size: 20px;
  font-weight: 600;
}

.shift-details {
  flex: 1;
  padding: 5vh 1.5vh 1.5vh 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shift-details-date {
  width: 50%;
  text-align: right;
}

.shift-details-date p {
  margin-top: 10px;
  font-size: 14px;
}

.shift-details-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.shift-details-info {
  width: 50%;
}

.shift-details-info h3 {
  margin-bottom: 10px;
  white-space: nowrap;
}

.address {
  font-size: 12px;
  font-weight: bold;
}

.shift-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--secondary-200);
  padding: 15px 20px;
}

.shift-footer-xs {
  display: none;
}

.shift-footer p {
  font-size: 12px;
}

.shift-rating {
  display: flex;
  align-items: center;
}

.shift-rating p {
  margin-left: 5px;
}

.shift-qualifications {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shift-qualifications p {
  color: var(--secondary-500);
  margin-right: 10px;
  white-space: nowrap;
}

.intro-overlay {
  transition: all 600ms ease;
}

.showInstructions {
  opacity: 1 !important;
  pointer-events: all !important;
}

.intro-background {
  position: absolute;
  height: calc(90vh - 280px);
  width: 85vw;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  backdrop-filter: blur(5px);
  bottom: 10vh;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  opacity: 0;
  transition: all 600ms ease;
  pointer-events: none;
}

.swipe-up-overlay {
  position: fixed;
  height: 44vw;
  width: 100vw;
  background: white;
  z-index: 101;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  box-shadow: var(--box-shadow);
}

.swipe-up-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.swipe-up-bar {
  border-radius: 15px;
  width: 40vw;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  height: 4px;
  background: black;
}
.swipe-up-text {
  text-align: center;
  padding: 8vw;
}

.swipe-up-text h3 {
  margin-bottom: 20px;
  margin-top: 10px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
}

.instructions {
  position: absolute;
  height: calc(90vh - 280px);
  width: 85vw;
  border-radius: 15px;
  bottom: 10vh;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  opacity: 0;
  transition: all 600ms ease;
  pointer-events: none;
}

.instructions img {
  object-fit: fill;
}

.emoticon {
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  z-index: 999;
  opacity: 1;
}

.emoticon-love {
  top: 70%;
  right: -20%;
  background: var(--primary-100);
  color: var(--orange);
}

.emoticon-trash {
  top: 70%;
  left: -20%;
  color: var(--secondary-900);
  background: var(--secondary-100);
}

.emoticon-love.animate {
  animation: inAnimationLove 1.2s ease-out;
}

.emoticon-trash.animate {
  animation: inAnimationTrash 1.2s ease-out;
}

.no-shifts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 3vw;
}

.no-shifts p {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary-500);
}

@keyframes inAnimationLove {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 30%;
    top: 50%;
  }
}

@keyframes inAnimationTrash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 30%;
    top: 50%;
  }
}
