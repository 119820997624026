.container {
  margin-bottom: 20px;
}

.other-inputs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.input {
  margin-right: 20px;
  width: 100%;
}

.input:last-child {
  margin-right: 0;
}

.validation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.validation p {
  margin-left: 20px;
}

.mobile-other-inputs {
  flex-direction: column;
}

.mobile-input {
  margin: 0px 0 !important;
}
