.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-bottom: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

.container p {
  font-size: 14px;
}

.hasUnread {
  background: var(--secondary-100);
}

.hasUnread p,
.hasUnread h5 {
  font-weight: 700;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.chevron {
  margin-left: auto;
  font-size: 10px;
  color: var(--secondary-900);
}

.preview {
  color: var(--secondary-600);
  margin-top: 5px;
}

.preview svg {
  margin-right: 10px;
  transform: translateY(-1px);
}
