.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 103;
  padding: 20px;
}


.header {
  padding: 20px 0;
}

.content h2 {
  font-size: 20px;
  margin: 10px 0;
}

.content p {
  font-size: 14px;
  margin: 10px 0;
}

.note {
  margin: 20px 0;
}

.note span {
  color: var(--orange);
  font-weight: 600;
}

.cancel-btn {
  width: 100%;
  margin: 20px 0;
}

.link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
