.wrapper {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.container {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  margin: 8px 0 10px 0;
  width: 100%;
}

.cards-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: flex-start;
  box-shadow: none;
  border-radius: 0px;
  justify-content: flex-start;
  width: 100%;
}

.cards-container::-webkit-scrollbar {
  display: none;
}

.radio-button-container {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid transparent;
  width: 100%;
}

.radio-button-container:hover {
  background: var(--secondary-100);
}

.radio-button-container.active:hover {
  background: white;
}

.radio-button-container:hover .radio-button {
  border: 1px solid var(--secondary-900);
}

.radio-button-container:last-child {
  border-bottom: none;
}

.radio-button-container.active {
  border: 1px solid var(--secondary-900);
}

.radio-button-container.active .radio-button {
  border: 1px solid var(--secondary-900);
  background: var(--secondary-900);
  color: white;
}

.radio-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid white;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-button-inner {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: white;
}

.radio-button-card {
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--border);
}

.radio-button-card.active {
  border: 1px solid var(--secondary-900);
  background: var(--secondary-900);
  color: white;
}

.label {
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
  font-size: 14px;
}

.label span {
  color: var(--orange);
}

.link {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-900);
}

/* Error */

.error-container {
  background: var(--error-light);
  border-radius: 5px;
  padding: 5px 10px;
}

.message {
  font-weight: 500;
  color: var(--error-dark);
  font-size: 14px;
}
