.stars {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.star {
  font-size: 50px;
  color: var(--secondary-300);
  margin: 0 20px;
  cursor: pointer;
}

.disabled {
  color: var(--disabled-background);
  cursor: auto;
}

.star-active {
  color: var(--warning-500);
  font-size: 50px;
  margin: 0 20px;
}

.mobile-star,
.mobile-star-active {
  font-size: 30px;
  margin: 0 10px;
}
