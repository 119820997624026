/* Main Filter Types */
.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: white;
}

.wrapper {
  height: calc(100% - 80px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.filter-type {
  display: flex;
  padding: 35px 20px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--border);
}

.filter-text {
  font-size: 14px;
}

.filter-text span {
  font-weight: 900;
  margin-right: 10px;
  width: 40px;
  display: inline-block;
  color: var(--secondary-900);
}

.filter-value {
  margin-left: auto;
  font-size: 12px;
  color: var(--secondary-600);
  font-weight: 600;
}

.filter-chevron {
  margin-left: 20px;
}

/* Actions*/
.filter-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-100);
  flex: 1;
}



/* Footer */
.footer-control {
  z-index: 120;
}

.control-btn {
  font-size: 14px;
}
