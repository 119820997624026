.container {
  position: relative;
  background: white;
  padding: 5px 15px;
}

.unread {
  background: var(--secondary-100);
}

.chevron {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.time p {
  font-size: 12px;
  color: var(--secondary-600);
  position: absolute;
  bottom: 10px;
  right: 15px;
}
