.container {
  overflow: scroll;
  height: calc(100vh - 140px);
}

.notifications h4 {
  font-size: 16px;
  margin: 20px;
}

.no-notifications {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-image {
  width: 75%;
  margin: 20px auto;
}

.no-notifications p {
  font-weight: 600;
  text-align: center;
  color: var(--secondary-900);
}
