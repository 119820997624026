.content-container {
  padding: 0 10px;
}

.content-text,
.content-text p {
  margin: 20px 0;
  font-size: 15px;
  font-weight: 600;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons-container button {
  width: 40%;
  margin: 40px 0 20px 0;
}

/* Mobile */

.mobile-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 105;
}

.mobile-wrapper.mobile-visible {
  display: relative;
}

.modal-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: white;
  box-shadow: var(--box-shadow);
  padding: 20px;
  border-radius: 15px 15px 0 0;
  z-index: 115;
  padding-bottom: 40px;
  pointer-events: all;
}

.label-mobile {
  padding: 0 10px;
}

.mobile-buttons-container {
  flex-direction: column-reverse;
}

.mobile-buttons-container button {
  width: 100%;
  margin: 10px 0;
}

