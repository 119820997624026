.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 110;
}

.icon {
  padding: 20px;
  width: 90%;
  transform: translateX(-15px);
}

.content {
  color: var(--secondary-900);
  padding: 20px;
  text-align: center;
}

.content h2 {
  margin: 20px 0;
  font-size: 23px;
  white-space: nowrap;
}

.content p {
  width: 80%;
  margin: 0 auto;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.buttons p {
  margin-top: 40px;
  color: var(--secondary-700);
  text-align: center;
  text-decoration: underline;
}
