/* Animation */
.fadeInTranslate {
  animation: inFadeTranslate 0.5s ease-in-out;
}

.fadeOutTranslate {
  animation: outFadeTranslate 0.5s ease-in-out;
}

.fadeIn {
  animation: inFade 0.5s ease-in-out;
  opacity: 1;
}

.fadeOut {
  animation: outFade 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

@keyframes inAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

@keyframes inFadeTranslate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes outFadeTranslate {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

@keyframes inFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes outFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
