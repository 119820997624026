.container {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 40px;
}

.title {
  padding: 20px;
  text-align: center;
  font-size: 20px;
}
.heading {
  padding: 20px;
  border-bottom: 1px solid var(--border);
  margin-bottom: 20px;
}

.heading-today {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  padding: 0 20px;
}

.badge {
  margin-left: 20px;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: var(--success);
}

.shifts-section {
  min-height: 30vh;
  border-bottom: 1px solid var(--border);
}
.no-shifts {
  padding: 10px 0px;
}

.no-heading {
  width: 100%;
  padding: 0 0 20px 20px;
}

.no-image {
  background: var(--secondary-200);
  border-radius: 15px;
  margin: 20px;
  padding: 10px;
  height: 35vh;
  min-height: 250px;
}

.no-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-button button {
  width: 75%;
}

.loading-spinner {
  font-size: 20px;
  color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-card {
  margin: 20px;
  border-radius: 15px;
  padding: 10px;
  height: 30vh;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.shifts-links {
  margin-top: 20px;
}

.bottom-links {
  margin-top: 20px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
}

.link p {
  font-weight: 600;
}

/* Applications */

.applications-section {
  padding: 20px;
}

.applications-notification {
  background: var(--disabled-background);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.applications-notification p {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
}
