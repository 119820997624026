.refresh {
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.refresh p {
  text-align: center;
  color: var(--secondary-900);
}

.refresh-content-button {
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  background: var(--secondary-500);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 7vw;
  margin: 20px 0;
  cursor: pointer;
}

.Refresh-touched .refresh-content-button {
  background: white;
  color: var(--secondary-900);
}

.Save-touched .refresh-content-button {
  background: white;
  color: var(--primary-900);
}
