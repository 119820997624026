.modal {
  z-index: 999;
  width: 90vw;
  transform: translate(-50%, -50%);
}
.overlay {
  z-index: 998 !important;
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0;
}

.children {
  flex: 1;
}

.buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.primary-btn {
  width: 100% !important;
}

.skip-link {
  margin-top: 20px;
}
