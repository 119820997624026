.image-container {
  margin: 20px auto;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background: var(--primary-100);
}

.heading {
  text-align: center;
  margin: 20px;
}

.heading h2 {
  font-size: 22px;
}

.text p,
.some-items {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.some-items {
  margin-bottom: 10px;
  font-weight: 700;
}

.todo {
  margin-top: 20px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
}

.icon {
  width: 20px;
  margin-right: 20px;
}

